<template>
	<div id="App">
		<test></test>
		<img src="./images/banner_02.png" style="width: 100%;" />
		<div class="cont">
			
		</div>
		<div class="suspension">
			<div class="su_left">
				<p>首页>人才招聘</p>
				<div class="su_fiex">
					<div class="c_l">
						<h4>{{detail.contentTitle}}<span>新</span></h4>
						<h1>1{{detail.contentKeyword}}</h1>
						<p>{{detail.contentDescription}}</p>
					</div>
					<div class="c_r">
						<p>发布时间：{{detail.updateDate}}</p>
						<router-link :to="{name:'apply', query:{ couponId: id }}"><button>申请职位</button></router-link>
					</div>
				</div>				
				<div class="c_text">
					<!-- <h2>岗位职责</h2> -->
					<!-- <img src="./images/zp_18.png" /> -->
					<p v-html="detail.contentDetails"></p>
				</div>
				<!-- <div class="c_text">
					<h2>岗位要求</h2>
					<img src="./images/zp_18.png" />
					<p>1、内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容<br/>
					2、内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</p>
				</div> -->
			</div>
			<div class="su_right">
				<h5><p>最新职位</p><span>更多></span></h5>
				<ul>
					<li  v-for="item in list" @click="details()"><span>新</span>{{item.contentTitle}}</li>					
				</ul>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot	
	},
  data(){
	return{
		id:'' ,
		detail:'',
		list:[]
	}
  },
  created(){
	var that = this
	 this.id = this.$route.query.id
	 console.log(this.id)
	 
	 this.$axios.get('/cms/content/get.do',{
		params:{            
		    id:this.id
		}
		 
	 }).then(function (res) {
		console.log(res);
		that.detail = res.data.data
	 	 
	 })
	 that.lists()
	 
  },
  methods:{
	  lists(){
		  var that = this
		  this.$axios.post('/cms/returnAll/officialWebsite').then(function (res) {
		  		console.log(res);
		  		that.list = res.data.categoryList[4].contentList
		  	 
		  })
	  },
	  details(id){
		var that = this
	  	window.console.log("查询成功",id);
	  	 this.$axios.get('/cms/content/get.do',{
			params:{            
				id:this.id
			}
	  	 		 
	  	 }).then(function (res) {
	  	 		console.log(res);
	  	 		that.detail = res.data.data
	  	 	 
	  	 })
	  },
  }
}
</script>

<style>
	.cont{
		height: 500px;
	}
	.su_right h5{
		display: flex;
		justify-content: center;
		text-align: left;
	}
	.su_right h5 p{
		flex: 3;
	}
	.su_right h5 span{
		flex: 1;
		font-size: 12px;
		font-weight: normal;
		text-align: right;
	}
	.su_right ul{
		margin-top: 20px;
	}
	.su_right ul li{
		cursor:pointer;
		margin: 15px 0;
		font-size: 14px;
		color: #666666;
		text-align: left;
	}
	.su_right ul li span{
		background-color: #EA0029;
		color: #fff;
		padding: 3px;
		font-size: 10px;
		border-radius: 5px;
		margin-right: 8px;
	}
	.su_left>p{
		font-size: 12px;
		color: #666666;
		text-align: left;
		margin-bottom: 20px;
	}
	.c_text{
		margin-top: 6%;
		text-align: left;
	}
	.c_text p{
		font-size: 14px;
	}
	.c_text h2{
		font-weight: 500;
		font-size: 20px;
	}
	.c_text img{
		width:60px;
		margin-bottom: 20px;
	}
	.suspension{
		position: absolute;
		top: 20%;
		left:17.5%;		
		width: 65%;
		max-width: 65%;
		display: flex;
		justify-content: center;
	}
	.su_left .c_r{
		flex: 4;
		text-align: right;
		
	}
	.su_left .c_r button{
		background-color: #EA0029;
		color: #fff;
		border: none;
		padding: 10px 20px;
		border-radius: 5px;
		font-size: 14px;
		margin-top: 15px;
	}
	.su_left .c_r p{
		margin-top: 10px;
		font-size: 12px;
		color:  #898383;
	}
	.su_left .c_l{
		flex: 6;
		text-align: left;
	}
	.su_left .c_l p{
		font-size: 13px;
		color: #898383
	}
	.su_left .c_l h4{
		font-weight: 500;
		font-size: 18px;
	}
	.su_left .c_l h1{
		color: #ea0029;
		margin: 10px 0;
	}
	.su_left .c_l h4 span{
		background-color: #ea0029;
		font-size: 14px;
		padding: 3px;
		color: #fff;
		border-radius: 5px;
		margin-left: 10px;
	}
	.su_fiex{
		margin-bottom: 12%;
		display: flex;
		justify-content: center;
	}
	.su_left{
		box-shadow: 4px 8px 15px #cccccc;
		background-color: #fff;
		flex: 7;
		padding: 3%;
		
	}
	.su_right{
		flex: 2;
		background-color: #fff;
		margin-left: 15px;
		padding: 2%;
	}
</style>
