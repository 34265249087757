<template>
  <div class="nav">
    <div class="main">
      <img src="../images/logo@1x.png" oncontextmenu="return false;" ondragstart="return false;"/>
      <ul>
		  <!--  @click="on(index)"  :class="index==idx?'active':''" -->
        <li v-for="(item,index) in navlist"  :key="index"><router-link :to='item.href'>{{item.name}}</router-link>

		</li>
		<!--   -->
		<li class="last" v-for="item in list"  @mouseenter="over" @mouseleave="out">
			{{item.name}}
			<ul class="second" v-if="active">
				<li v-for="info in item.secondMenus" >
					<router-link :to='info.href'>{{info.name}}</router-link>
				</li>
			</ul>
		</li>

        <!-- <li><router-link to='/login'>登录</router-link> | <router-link to='/register'>注册</router-link></li> -->
      </ul>
    </div>
  </div>

</template>

<script>

export default {
// inject:['reload'],
  name: 'test',
  props: {},
  data() {
    return {
	  type:1,
	  active:false,
	  navlist:[
		  {
				href:'/index',
				name:'首页'
		  },
		  {
				href:'/Notice',
				name:'公告新闻'
		  },
		  {
				href:'/personnel',
				name:'人才招聘'
		  },

	  ],
	  list:[
		  {
			href:'',
			name:'关于我们',
			secondMenus:[
				{
					href:'/about',
					name:'企业简介',
				},
				{
					href:'/common_problem',
					name:'常见问题',
				}
			]

		  },
	  ],
	  // idx:0,

    }
  },
  created(){
	//location.reload();

  },
  mounted() {

  },
  methods: {

	  displays(type){
		  console.log(type)
		  this.active = true
	  },
	// 鼠标移入加入class
	  over(){
		   console.log('移入')
		  this.active = true
	    //$event.currentTarget.className="actives";
	  },

	  out(){
		  console.log('移除')
		  this.active = false
	    //$event.currentTarget.className="";
	  }
  },


}

</script>

<style>
	.last{
		cursor: pointer;
	}
.product{
	margin-top: 35px;
}
.actives{
	display: block;
}
.nav .main>ul .second{
	/* display: none; */
	position: absolute;
	top: 40px;
	left: 50%;
	transform: translateX(-50%);
}
.nav .main ul .second li{
		border-bottom: 1px solid #fff;
}
.nav .main ul .second li{
	background: rgba(0,0,0,0.5);
	color: #fff;
	width: 60px;
	padding: 10px;

}

.active {
  border-bottom: 2px solid #EA0029;
  margin-top: 10px;
}

.clear {
  border: none;
}

.nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
}

.nav .main {
  width: 1000px;
  margin: 0 auto;
  padding:10px 0
}

.nav img {
  width:180px;
}

.nav .main>ul {
  margin-left:4%;
  flex: 6;
  display: flex;
  justify-content: center;
  color: #fff;
}
.nav .main>ul>li:last-child{
	position: relative;
}
.nav .main>ul>li a{
	color: #fff;
	padding: 0 2px;
	text-decoration: none;
}
.nav .main>ul>li:nth-child(4){
	flex: 2.3;
}
.nav .main>ul>li:nth-child(5){
	flex: 1.2;
}
.nav .main>ul>li:nth-child(6){
	flex: 1.2;
}
.nav .main>ul>li:nth-child(7){
	flex: 1.2;
}
.nav .main>ul>li:nth-child(2){
	flex: 1.4;
}
.nav .main>ul>li:last-child{
	flex: 1.4;
}
.nav .main>ul>li {
  font-size: 13.5px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  background: rgba(0, 0, 0, 0.5);
}
</style>
