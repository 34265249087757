<template>
	<div class="content">
		<div class="main">
			<div class="left">
				<h3>联系我们</h3>
				<img src="../images/zp_18.png"/>
				<div class="p">
					<p>地址：青岛·崂山 海尔路大荣中心A座31层</p>
					<p style="padding-left: 35px;">青岛·崂山 松岭路131号22层</p>
					<p>工作时间：8:30-17:30</p>
					<i>客服电话：<span>400-068-7979</span></i>
				</div>
			</div>
			<div class="center">
				<div class="img">
					<img src="../images/zp_23.png" style="width: 100px;"/>
					<p>国晟普惠</p>
				</div>
			</div>
			<div class="right">
				<img src="../images/zp_15.png" oncontextmenu="return false;" ondragstart="return false;"/>
				<div class="rightLinks">
					<div>
						<router-link to='/index'>首页</router-link>
					</div>
					<div>
						<router-link to='/Notice'>公告新闻</router-link>
					</div>
					<div>
						<router-link to='/personnel'>人才招聘</router-link>
					</div>
					<div>
						<router-link to='/about'>关于我们</router-link>
					</div>
					<div>
						<img src="../images/ghIcon.png" @click="goBeian"/>
						<a href="https://beian.mps.gov.cn/#/query/webSearch?code=37021202001492" rel="noreferrer" target="_blank">鲁公网安备37021202001492</a>
					</div>
				</div>
				<div class="imgDiv">
					<img src="../images/jyxkz.png" @click="toReserved"/>
				</div>
				<p>Copyright 2019-2023 www.guoshengxd.com .All Rights Reserved <a href="https://beian.miit.gov.cn" style="color: #333;">鲁ICP备2021009663号-2</a></p>
			</div>
		</div>
		<div class="customer_service">
			<div class="wei" v-show="hide">
				<img src="../images/zp_23.png" style="width: 100px;"/>
				<p>扫码进入<br>国晟普惠</p>
			</div>
			<ul @mouseenter="over" @mouseleave="out">
				<li>
					<img src="../images/zp_07.png"/>
					<p>微信公众号</p>
				</li>
				<!-- <li><router-link to='/suggest'>
					<img src="../images/zp_10.png" />
					<p>投诉建议</p>
					</router-link>
				</li> -->
			</ul>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'foot',
		props: {},
		methods: {
			over() {
				this.hide = true
			},
			out() {
				this.hide = false
			},
			wei() {
				this.hide = true
			},
			hides() {
				this.hide = false
			},
			//跳转经营许可证
			toReserved(){
				const routeData = this.$router.resolve({
					name:'reserved',
				})
				window.open(routeData.href, '_blank')
			},
			//跳转公安备案
			goBeian(){
				window.open('https://beian.mps.gov.cn/#/query/webSearch?code=37021202001492', '_blank')
			}
		},
		data() {
			return {

				hide: false
			}
		},

	}
</script>

<style>
	.customer_service a{
		color: #333
	}
	.customer_service a{
		text-decoration: none;
	}
	.wei{
		position: absolute;
		left: -150px;
		text-align: center;
		background-color: #fff;
		box-shadow: 0 0 10px #ccc;
		padding: 13px 20px;
		border-radius: 5px;
		top: -70px;
		/* display: none; */
	}
	.wei p{
		line-height: 1.5;
		margin-top: 10px;
	}
	*{
		margin: 0;padding: 0;
	}
	li{
		list-style: none;
	}
	.customer_service{
			position: fixed;
			right: 0;
			top: 35%;
			z-index: 10;
	}
	.customer_service ul{
		background-color: #fff;
		border-radius: 5px 0 0 5px;
		padding: 15px;
		box-shadow: 0 0 10px #ccc;
		font-size: 14px;
	}
	.customer_service ul li{
		margin-bottom: 15px;
	}
	body{
		margin: 0;padding: 0;
	}
	.right{
		flex:5;
		text-align: right;
	}
	.right .rightLinks{
		display: flex;
		flex-direction: row;
		justify-content:flex-end;
		margin-top: 10px;
	}
	.right .rightLinks div{
		display: flex;
		flex-direction: row;
		align-items:center;
		font-size: 13px;
		height:20px;
		margin-left:40px;
	}
	.right .rightLinks div img {
		width:20px;
		margin-right:7px;
		font-size: 0;
		cursor: pointer;
	}
	.right div a{
		color: #333
	}
	.right p{
		font-size: 12px;
		line-height: 2;
	}
	.right .imgDiv{
		width:100%;
		height: 58px;
	}
	.imgDiv img{
		width:60px;
		margin-top:9px;
		cursor: pointer;
	}
	.content{
		font-size: 14px;
		padding: 76px 0;
		background-color: #f3f3f3;
	}
	.main{
		width:1000px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		align-content:flex-end
	}
	i span{
		font-size: 20px;
		color: #333;
	}
	i{
		font-style: normal;
		margin-top: 20px;
		display: block;
		font-size: 12px;
		color: #666;
	}
	h3{
		margin: 10px 0;
	}
	.content .left{
		flex: 2;
		text-align: left;
	}
	.center{
		flex: 1;
		align-items: center;
		display: flex;
		/* justify-content: center; */
	}
	.center .img{
		float: left;
		margin-top: 50px;
		margin-right: 10%;
	}
	.left img{
		margin-bottom: 20px;
	}
	.left p{
		font-size: 12px;
		margin: 5px 0;
	}
	.left .p{
		border-right: 1px solid #ececec;
		text-align: left;
	}
</style>
